@import '~bulma';

.recipes {
  background-color: $white-ter;
  .section {
    padding-top: 24px;
  }
  .card {
    .card-content {
      h4 {
        margin-bottom: 10px;
      }
      padding: 16px 16px 16px 16px;
    }
  }
}