@import '~bulma';

.recipes .editor {
  hr {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .op-button {
    cursor: pointer;
    margin-left: 5px;
    margin-right: 5px;
  }


  .disabled {
    color: $grey-light;
    cursor: not-allowed;
  }

  .cover-img {
    .img-dropzone {
      min-height: 200px;
    }
  }

  .step {
    h3 {
      font-weight: bolder;
      margin-bottom: 5px;
    }

    hr {
      margin-top: 15px;
      margin-bottom: 15px;
    }

    .level {
      margin-bottom: 0px;
    }
  }
}