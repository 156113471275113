.slot {
  .icon {
    border: solid 1px;
    padding: 1px 1px 1px 1px;
    text-align: center;
    width: 97%;
    height: 97%;
    img {
      width: 70%;
      height: 70%;
    }
  }
  .icon-on {
    background-color:  #E76E6D;
  }
  .icon-off {
    background-color: #C5C68E;
  }
}