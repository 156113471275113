.slot {
  .win {
    display: inline-block;
    .name {
      text-align: center;
      background:  #FF9D37;
      border: solid 1px;
      color: black;
      font-size: 20px;
      font-weight: bold;
      font-family: serif;
    }
  }
}