.slot {
  .bet-panel {
    table {
      margin-left: auto;
      margin-right: auto;
      border-spacing: 2px;
      border-collapse: unset;
      td {
        border: 1px solid;
        width: 55px;
      }
      .name {
        background-color: #AFD6FF;
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        font-family: serif;
      }
      .icon {
        border: none;
        vertical-align: middle;
        width: 100%;
        height: 45px;
      }
    }
    .disabled {
      opacity: 0.7;
    }
  }
}