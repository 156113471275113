.slot {
  .carousel {
    margin-left: auto;
    margin-right: auto;
    td {
      width: 68px;
      height: 60px;
      .icon {
        width: 96%;
        height: 96%;
      }
    }
  }
}