.header {
  .navbar-item {
    img {
      max-height: 48px;
    }
    .logo {
      height: 32px;
    }
    .login-icon {
      max-height: 100%;
      font-size: 28px;
    }
  }
}
