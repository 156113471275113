@import '~bulma';

.diary .entry {
  .message {
    margin: 0px 0px 10px 0px;
    padding: 0px;
    .edit-icon {
      cursor: pointer;
      padding-right: 8px;
    }
    .del-icon {
      cursor: pointer;
    }
    hr {
      margin: 16px 0px 16px 0px;
      border-top: 2px dashed $info;
    }
  }
  .tag {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-right: 8px;
  }
}