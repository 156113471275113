@import "~bulma/sass/utilities/_all";
@import "~bulma-calendar/src/sass/index";

.img-dropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
  border: dashed 2px $grey-lightest;
  background-color: $white-bis;

  img {
    padding: 4px;
  }

  .preview .delete {
    margin: 10px 10px -30px -30px;
  }
}
