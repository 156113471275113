.slot {
  .led {
    text-align: center;
    background: #330000;
    .lo {
      opacity: 0.3;
    }
    img {
      vertical-align: text-bottom;
      width: 24px;
      height: 32px;
    }
  }
}