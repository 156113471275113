@import '~bulma';

.recipes .recipe {
  background-color: $white-ter;
  .header {
    h1 {
      margin-bottom: 0px;
    }
    hr {
      margin-top: 10px;
      margin-bottom: 16px;
    }
  }
  .cover-img {
    background: no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .ingredients {
    padding-bottom: 24px;
    .ingredient-group {
      .title {
        margin-bottom: 10px;
      }
      .columns {
        margin: 0px;
        .column {
          padding: 0px 8px 10px 8px;
          .ingredient {
            overflow: hidden;
            padding: 0px 0px 0px 5px;
            border-left: 6px $info solid;
            border-bottom: 1px $info solid;
          }
        }
      }
    }
  }
  .steps {
    h1 {
      margin-bottom: 0px;
    }
    hr {
      margin-top: 5px;
      margin-bottom: 5px;
    }
    .columns {
      margin: 0px 0px 0px 0px;
    }
    .step {
      .title {
        margin-bottom: 0em;
      }
    }
  }
}
