@import '~bulma';

.diary .datetimepicker {
  max-width: 250px;
  .datepicker-nav {
    padding: 0;
  }
  .datepicker-body .datepicker-dates {
    .datepicker-weekdays {
      padding: 0;
      div {
        flex: 14.28571% 1;
        text-align: center;
      }
    }
    .datepicker-days .datepicker-date .date-item {
      height: 1.9rem;
      width: 1.9rem;
    }
  }
  .datetimepicker-footer {
    .button {
      color: $link !important;
      padding: 0;
    }
  }
}
